import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrafficLight, faUserFriends, faGavel, faUserInjured, faScroll, faHome } from '@fortawesome/free-solid-svg-icons'

const IndexPage = () => (
  <Layout>
    <Hero />
    <Seo title="Family Law and Divorce Attorney in Annapolis MD" description="At The Law Office of Sean E Becker, LLC we provide an understanding ear as well as over 10 years of family law experience to help you through your situation. " />
    <div className="row g-0 justify-content-center">
      <div className="col-12 col-md-10 col-lg-9 col-xl-8 home-intro">
        <p>
        For <strong>10+ years</strong>, the Law Office of Sean E. Becker, LLC has established a solid reputation as the local <strong>go-to lawyer</strong> in Cape St Claire, Maryland. Proud of his customer following, his loyal customers have referred him time and time again to their families and friends. Today, his practice spans throughout <strong>Annapolis and Anne Arundel &amp; Queen Anne’s Counties</strong>.
        </p>
      </div>      
    </div>        
    <h2 className="sr-only">Top Services in Maryland</h2>
    <div className="services-wrapper">   
      <div className="service-card">
        <Link to="/criminal-law"><FontAwesomeIcon icon={faGavel} size="2x" /><br />Criminal Law</Link>   
      </div>
      <div className="service-card">
        <Link to="/domestic-violence"><FontAwesomeIcon icon={faUserInjured} size="2x" /><br />Domestic Violence</Link>   
      </div>
      <div className="service-card">
        <Link to="/wills-n-estates"><FontAwesomeIcon icon={faHome} size="2x" /><br />Estates </Link>   
      </div>
      <div className="service-card">
        <Link to="/family-law-n-divorce"><FontAwesomeIcon icon={faUserFriends} size="2x" /><br />Family Law / Divorce & Custody</Link>   
      </div>
      <div className="service-card">
        <Link to="/traffic-violations-n-DWI-DUI"><FontAwesomeIcon icon={faTrafficLight} size="2x" /><br />Traffic Violations & DWI/DUI</Link>   
      </div>
      <div className="service-card">
        <Link to="/wills-n-estates"><FontAwesomeIcon icon={faScroll} size="2x" /><br />Wills </Link>   
      </div>      
    </div>        
  </Layout>
)

export default IndexPage
