import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => (
    <div className="hero-wrapper" style={{ display: "grid" }}>
      <StaticImage
        style={{
          gridArea: "1/1",          
        }}
        layout="fullWidth" 
        loading="eager" 
        aspectRatio={2 / 1}
        alt=""
        // Original Photo by Tyler Nix Unsplash  
        src={
          "../images/fam-bg-7a.jpg"
        }
        formats={["auto", "jpg", "avif"]}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",          
        }}
      >
        <h1 className="hero-h1">
        PROTECT YOURSELF, PROTECT YOUR FAMILY
        </h1>
      </div>
      <div className="hero-btn"
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        
        <Link to="/contact">CONTACT US TO GET STARTED</Link>
      </div>
    </div>
  )
  
  export default Hero